<template>
  <v-card>
    <v-card-title class="header"
      >Как работать в админ панели-системы AyanMarket</v-card-title
    >
    <v-card-text class="work">
      <v-list class="work_list">
        <!-- "admin_errors_watch" на проверку этих прав повешано много ненужных для локального админа страниц -->
        <div
          v-for="group in itemsGroup"
          :key="group.name"
          :class="activeGroup === group.name && 'active-div'"
          @click="activeGroup = group.name"
        >
          <v-list-group no-action>
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title class="work-routes">{{
                  group.name
                }}</v-list-item-title>
              </v-list-item-content>
            </template>
            <v-icon
              slot="appendIcon"
              :color="activeGroup === group.name ? 'white' : 'black'"
              >mdi-chevron-down</v-icon
            >
            <!-- пути в подгруппах -->
            <div
              v-for="item in group.items"
              :key="item.name"
              class="work_item"
              :class="activeItem.name === item.name && 'active-item'"
            >
              <v-list-item @click="activeItem = item">
                <v-list-item-content>
                  <v-list-item-title class="item-route">{{
                    item.name
                  }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </div>
          </v-list-group>
        </div>
      </v-list>

      <div class="presentation">
        <p class="presentation_title">{{ activeItem.name }}</p>
        <p v-for="(text, i) in activeItem.data" :key="i">{{ text }}</p>
      </div>
    </v-card-text>
  </v-card>
</template>
<script>
export default {
  data() {
    return {
      activeGroup: "",
      activeItem: "",
      itemsGroup: [
        {
          name: "Заказы",
          items: [
            {
              name: "Работа с товарами",
              data: [
                "some info",
                "some info2",
                "Нужна информация для каждой этой страницы, какой то текст с инструкциями",
              ],
            },
            {
              name: "Добавление товаров",
            },
            {
              name: "Настройка лимитов по отображению",
            },
            {
              name: "Массовая работа с товарами",
            },
            {
              name: "Редактирование цен и остатков вручную",
            },
          ],
        },
        {
          name: "Товары",
          items: [],
        },
        {
          name: "Отчеты",
          items: [],
        },
      ],
    };
  },

  async created() {},
  methods: {},
};
</script>
<style lang="scss">
.header {
  background: linear-gradient(to right, #e93e7b 50%, white 70%);
  margin-left: -12px;
  color: white;
  padding: 5px 10px;
}
.work {
  display: flex;
  justify-content: space-between;
  &_list {
    width: 30%;
  }
  &_item {
    margin: 3px 0 0 30px;
  }
}
.presentation {
  width: 50%;
  margin-top: 20px;
  &_title {
    font-weight: 700;
    color: black;
    font-size: 20px;
  }
}
.active-div {
  .v-list-group__header {
    background-color: #5d4bd0 !important;
    border-radius: 15px;
  }
  .work-routes {
    color: white;
  }
}
.active-item {
  background-color: #5d4bd0;
  border-radius: 15px;
  .item-route {
    color: white;
  }
}
.item-route {
  color: black;
}
.work-routes {
  color: black;
}
</style>
